
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.second-page-title-container {
  text-align: center;
  position: relative;
  top: 0rem; /* Adjust as needed to move it lower */
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  padding: 1rem
}

.second-page-title {
  font-size: 3rem; /* Adjust the font size as needed */
  font-weight: 400; /* Normal font weight */
  color: #ffffff; /* Adjust the color as needed */
  line-height: 1.5; /* Adjust the line height for readability */
}

.second-page-text-container {
  text-align: center;
  position: relative;
  top: 0rem; /* Adjust to align with the title */
  left: 50%;
  transform: translateX(-50%);
  /* max-width: 1000px; Optional: to limit the width of the text block */
  padding: 1rem; /* Optional: to add some padding around the text */
  z-index: 2;
}

.second-page-text {
  font-size: 1.5rem; /* Adjust the font size as needed */
  font-weight: 400; /* Normal font weight */
  color: #ffffff; /* Adjust the color as needed */
  line-height: 1.5; /* Adjust the line height for readability */
}

.image-container{
position: relative;
top: 3rem;
/* max-width: 80rem;
min-height: 10rem; */

}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}




@media (max-width: 600px) {
  .image-container{
    width: 400px;
    height:15rem;
    /* align-items: center; */
    margin-left: -2.5rem;

  }
  
}

@media (min-width: 800px) and (max-width: 999px) {
  .image-container {
    width: 50rem;
    height: 45rem;
    margin-left: -2.5rem;
  }
}


@media (min-width: 1000px) and (max-width: 1100px) {
  .image-container {
    width: 60rem;
    height: 50rem;
    margin-left: -2.5rem;
  }
}
